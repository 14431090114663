import React, {useContext} from 'react';
import Layout from '../components/layout';
import Marketo from '../components/marketo';
import GlobalPartnerProgram from '../assets/images/global-partner/partners logo.svg';
import {graphql} from 'gatsby';
import {mapStickyBanner, mapGartner} from "../common/utils";
import {IS_MOBILE} from "../common/consts"
import HeroRings from '../assets/images/global-partner/hero rings.svg';
import CatoImg from '../components/cato-image';
import {GlobalDispatchContext} from "../context/GlobalContextProvider";
import CatoSlider from '../components/slider';
import TestimonialBlock from '../components/testimony-quote-block';
import {ACTION_TYPES, PARTNERS_LOBBY_FORM_IDS, LANG} from "../common/consts";
import useDeviceDetect from '../hooks/useDeviceDetect';
import '../assets/styles/pages/global-partners.scss';
import '../assets/styles/pages/partners-lobby.scss'


const PartnerPage = ({data, pageContext, location}) => {

    const {isMobile} = useDeviceDetect();

    const dispatch = useContext(GlobalDispatchContext);

    const {wpPage} = data;

    const {headingWithParagraph, partnersLobby} = wpPage;

    const {partnersBannerLinks, partnersFormHeading, partnersTestimonialsSection: {videos, quotes, heading, subheading}} = partnersLobby;

    const stickyBanner = mapStickyBanner(wpPage);

    const gartner = process.env.GATSBY_MODE !== 'preview' ? mapGartner(wpPage) : null;

    const videosList = videos && videos.length
        ?
        videos.map(video => ({
            vidId: video.code,
            title: video.title,
            vidImg: video.image ? video.image : null,
            logo: video.logo || null
        }))
        :
        null

    const quotesList = quotes && quotes.length ? quotes.map(quote => ({quote: quote.quote, source: quote.quotee, title: quote.quoteeTitle, logo: quote.companyLogo})) : null;

    const toggleVideo = (e) => {
        const index = e.target.dataset.index;
        const video = videosList[index];
        dispatch({type: ACTION_TYPES.CHOOSE_HEADER_VIDEO, payload: video});
        dispatch({type: ACTION_TYPES.TOGGLE_HEADER_VIDEO});
    }

    const quotesElements = quotesList ? quotesList.map((quote, i) => <TestimonialBlock {...quote} />) : null;

    return (
        <Layout
            className="partners-lobby"
            lang={pageContext.lang}
            breadcrumbs={[{title: wpPage.title, path: location.pathname}]}
            whiteMenu={false}
            yoastData={wpPage.seo || null}
            seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
            title={wpPage.title}
            gartner={gartner}
            seoPath={location.pathname}
            translations={pageContext.translations}
            wpid={wpPage.databaseId}
            {...{stickyBanner}}>
            <section className={'banner'}>
                <CatoImg img={GlobalPartnerProgram} className="partners-program-logo"/>
                <div className="text extra-left-padding">
                    {headingWithParagraph && headingWithParagraph.headingParagraphHeadline && <h1>{headingWithParagraph.headingParagraphHeadline}</h1>}
                    {headingWithParagraph && headingWithParagraph.headingParagraphParagraph && <div dangerouslySetInnerHTML={{__html: headingWithParagraph.headingParagraphParagraph}} />}
                    <div className="links">
                        {partnersBannerLinks && partnersBannerLinks.length && partnersBannerLinks.map((section, i) => (
                            <div key={`partners-lobby-banner-links-${i}`}>
                                {section.listTitle && <h3>{section.listTitle}</h3>}
                                <ul>
                                    {section.links && section.links.length && section.links.map((link, j) => (
                                        <li key={`section-links-banner-links-${link.label}-${j}`}>
                                            <a href={link.link}>
                                                {link.icon && <CatoImg img={link.icon}/>}
                                                {link.label && <span>{link.label}</span>}
                                            </a>
                                        </li>)
                                    )}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="form-wrapper">
                    {partnersFormHeading && <h2>{partnersFormHeading}</h2>}
                    <Marketo formId={PARTNERS_LOBBY_FORM_IDS[pageContext.lang] || PARTNERS_LOBBY_FORM_IDS[LANG.ENG]} event={{label: location.pathname}}/>
                </div>

                {
                    !isMobile &&
                    <CatoImg img={HeroRings} className="rings"/>
                }
            </section>
            <section className="testimonials">
                <div className="videos extra-left-padding">
                    {heading &&  <h2>{heading}</h2>}
                    {subheading && <span>{subheading}</span>}
                    <ul className="videos-list">
                        {videosList && videosList.map((video, key) => (
                            <li onClick={toggleVideo} data-index={key} key={`video-partners-${key}`}>
                                <div className="img-wrapper">
                                    <CatoImg img={video.vidImg}/>
                                </div>
                                <div className="text">
                                    <CatoImg img={video.logo}/>
                                    <span>{video.title}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="quotes">
                    {quotesElements && <CatoSlider
                        dots={false}
                        arrows={true}
                        fade={true}
                        centerPadding={0}
                    >
                        {quotesElements}
                    </CatoSlider>
                    }
                </div>
            </section>
        </Layout>
    )
}
export default PartnerPage;

export const pageQuery = graphql`
    query partnersPageQuery($id: String!) {
        wpPage(id: { eq: $id }) {
            ...YoastData
            title
            content
            slug
            databaseId
            id
            date(formatString: "MMMM DD, YYYY")
            path: uri
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            headingWithParagraph {
                headingParagraphParagraph
                headingParagraphHeadline
            }
            headlineLoneHeadline {
                loneHeadline
            }
            
            partnersLobby {
                partnersBannerLinks {
                    listTitle
                    links {
                        icon {
                            altText
                            localFile {
                                publicURL
                            }
                        }
                        label
                        link
                    }
                }
                partnersFormHeading
                partnersTestimonialsSection {
                    heading
                    subheading
                    videos {
                       title
                       code
                       image {
                         altText
                         localFile {
                            publicURL
                         }
                       } 
                       logo {
                          altText 
                          localFile {
                            publicURL
                          }
                       }
                    }
                    quotes {
                        quote
                        quotee
                        quoteeTitle
                        companyLogo {
                            altText 
                            localFile {
                                publicURL
                            }
                        }
                    }
                }
            }
            
            splitScreenText {
                splitScreenTextRow {
                    splitHeadline
                    splitSubheading
                    splitButtonText
                    splitButtonLink
                    splitRightSide {
                        splitRightBold
                        splitRightLight
                    }
                }
            }
            ...ResourcesBannerRows
            ...StickyBanner
            ...GartnerStrip
        }
        site {
            id
            siteMetadata {
                title
            }
        }

}`
